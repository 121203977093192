import mqtt from 'mqtt/dist/mqtt.js';
import message from "@/utils/message";
import global from "../components/Global";
import {getToken} from "./auth";
import axios from "axios";

export default new class {

    /**
     * mqtt客户端对象.
     */
    #client = {};

    /**
     * 下发指令时的消息ID.
     */
    #messageId = '';

    /**
     * mqtt超时时间定时器ID.
     */
    #timerId = 0;

    /**
     * 储能业务指令下发HTTP请求，并等待mqtt消息返回.
     * @param {Object} option 请求参数
     * {
     *      deviceSn: '',
     *      data: {} || [{}, {}], // 一个指令对象{commandCode: 'A', ...} 也可以是一个指令数组[{commandCode: 'A', ...}, {commandCode: 'B', ...}]
     *      httpTimeout: 30000,
     *      httpFailCallback: function(data) {}
     *      mqttMessageCallback: function(message, topic) {}
     *  }
     */
    sendEsaioBusinessCommand(option) {

        let token = getToken();
        option['url'] = '/device/sendEsaioBusinessCommand';
        option['topic'] = global.mqttDeviceCmdTopicPrefix + token;
        option['isAutoDisconnectMqtt'] = true;
        option['isFilterMessageId'] = true;
        option['server'] = global.esaioServerBase;
        this.mqttRequest(option);
    }

    /**
     * 下发连接前先主动关闭连接.
     * @param {Object} option 请求参数
     * {
     *      loading: Object,
     *      url: '',
     *      data: {},
     *      httpTimeout: 30000,
     *      topic: '',
     *      isAutoDisconnectMqtt: true,
     *      isFilterMessageId: true,
     *      httpSuccessCallback: function(data) {},
     *      httpFailCallback: function(data) {},
     *      mqttMessageCallback: function(message, topic) {}
     *  }
     */
    mqttRequest(option) {

        if (!option.url) {

            throw 'url must not null.';
        }
        this.disconnectMqtt();
        // mqtt主动关闭有延迟，100ms后再建立新连接，调用此方法前应先打开遮罩，防止多次触发
        setTimeout(() => {

            this.connectedMqtt(option);
        }, 100);
    }

    /**
     * 下发HTTP请求，并等待mqtt消息返回.
     * @param {Object} option 请求参数
     * {
     *      loading: Object,
     *      url: '',
     *      data: {},
     *      httpTimeout: 30000,
     *      topic: '',
     *      isAutoDisconnectMqtt: true,
     *      isFilterMessageId: true,
     *      httpSuccessCallback: function(data) {},
     *      httpFailCallback: function(data) {},
     *      mqttMessageCallback: function(message, topic) {}
     *  }
    */
    connectedMqtt(option) {

        let that = this;
        let token = getToken();
        let connection = {

            clientId: global.mqttClientIdPrefix + token,
            username: global.mqttServerUserName,
            password: global.mqttServerPassword,
        };
        that.#client = mqtt.connect(global.mqttServerSite + '/mqtt', connection);
        that.#client.on('connect', function(obj) {

            console.info('MQTT连接成功');
            that.#client.subscribe(option.topic, {qos: 0}, function(err, granted) {

                if (err) {
                    // 关闭loading
                    that.closeLoading(option.loading);
                    console.error('MQTT订阅失败' + JSON.stringify(err));
                    that.disconnectMqtt();
                } else {

                    console.info('MQTT订阅成功' + option.topic);
                    // 下发http请求时，默认添加时间戳作为消息ID，并记录这个ID用于消息推送回来时的确认
                    that.#messageId = new Date().getTime();
                    option.data['messageId'] = that.#messageId;
                    let httpParam = {

                        url: option.server + option.url,
                        data: option.data,
                        timeout: option.httpTimeout || 30000,
                        header: {

                            "Authorization" : token,
                            // "Accept-Language": tool.getLanguage()
                        },
                        method: 'POST',
                        dataType: 'json',
                    }
                    axios(httpParam).then(res => {

                        that.defaultHttpSuccessCallback(res, option);
                    }).catch(err => {

                        console.error('HTTP请求下发失败，网络原因');
                        err.msg = '请求失败';
                        that.defaultHttpFailCallback(option, err);
                    });
                }
            });
        }).on('message', function(topic, message) {

            console.info('MQTT服务器返回内容：' + message);
            let msg = JSON.parse(message);
            // 如果messageID相等或者时升级程序时，则调用回调方法，否则等待超时
            if ((option.isFilterMessageId && that.#messageId == msg.messageId) || !option.isFilterMessageId) {
                // 关闭loading
                that.closeLoading(option.loading);
                option.mqttMessageCallback(msg, topic);
                if (option.isAutoDisconnectMqtt) {

                    that.disconnectMqtt();
                }
            }
        }).on('error', function(error) {
            // 关闭loading
            that.closeLoading(option.loading);
            console.error('MQTT发生异常，' + error);
            that.disconnectMqtt();
        });
    }

    defaultHttpSuccessCallback(res, option) {

        if (res.code === global.response_status_success_obj) {

            console.info('HTTP请求下发成功');
            // 判断是否存在自定义回调，有则回调
            if (option.httpSuccessCallback) {

                option.httpSuccessCallback(res);
            }
        } else {

            console.info('HTTP请求下发失败，错误代码res.code：' + res.code);
            this.defaultHttpFailCallback(option, res);
        }
    }

    defaultHttpFailCallback(option, res) {
        // 关闭loading
        this.closeLoading(option.loading);
        this.disconnectMqtt();
        // 判断是否存在自定义回调，有则回调
        if (option.httpFailCallback) {

            option.httpFailCallback(res);
        } else {
            // 没有定义错误回调并且如果是ServiceException则弹框 避免和baseApi里弹框重复
            if (res.code === global.response_status_service_msg) {

                message.error(res.msg);
            }
        }
    }

    /**
     * 断开MQTT连接.
     */
    disconnectMqtt() {

        if (typeof this.#client.connected != 'undefined' && this.#client.connected) {

            console.info('MQTT连接断开');
            this.#client.end(true);
            this.#client = {};
            this.#messageId = '';
        }
    }

    /**
     * 关闭loading.
     * @param loading 传入的loading对象
     */
    closeLoading(loading) {

        if (loading) {

            loading.close();
        }
    }
}
